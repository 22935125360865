import { ref, watch } from "vue";
import { useBuilder } from "@/views/builder/stores/Builder";
import { sections, columnTableTemplate, columnSettingsTemplate } from "@/views/builder/config/BuilderConfig";

export function useSection() {
  const store = useBuilder();
  const sectionData = ref();
  const sectionFields = ref();

  const initSection = async (sectionName) => {
    store.$patch((state) => {
      state.currentSection.name = sectionName;
      state.currentSection.data = null;
      state.currentSection.status = false;
    })

    const sectionConfig = sections.find(section => section.name == sectionName);

    sectionData.value = {};
    sectionFields.value = [] as any;

    if (sectionConfig) {
      for (const field of sectionConfig.items) {
        sectionData.value[field.name] = store.getCurrentComponent[field.name];
      }
      
      await sectionConfig.items.map(field => {
        const item = {
          name: field.name,
          type: field.type,
          label: field.label,
          defaultValue: store.getCurrentComponent[field.name],
          options: field.options || null
        }
        sectionFields.value.push(item);
      })
    }

    store.$patch((state) => {
      state.currentSection.status = true
    })
  }

  const updateSection = (data) => {
    store.$patch((state) => {
      state.currentSection.data = data;
      state.transferData[store.getCurrentSectionName] = data;
    })
  }  
  
  return { 
    initSection,
    sectionData,
    sectionFields,
    updateSection,
  }
}